.no-offer-page {
    height: 100%;
    overflow: hidden;
    
    .banner {
        background: url('./background.jpg');
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        z-index: -1;
        height: calc(100vh - 70px);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }

    .label-1 {
        position: fixed;
        font-size: 64px;
        top: 30%;
        left: 20%;
        right: 20%;
        font-weight: 700;
        color: white;
        width: 60%;
        text-align: center;
        z-index: 0;

        @media (max-width: 1000px) {
            font-size: 55px;
            width: 80%;
            left: 10%;
            right: 10%;
        }

        @media (max-width: 780px) {
            font-size: 35px;
            left: 5%;
            right: 5%;
            width: 90%;
        }

        @media (max-width: 500px) {
            font-size: 30px;
        }
    }
}