.terms-page {
    background-color: #f9f9f9;

    .terms-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50%;
        margin-left: 25%;
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;

        @media (max-width: 750px) {
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
        }

        .title {
            margin-top: 50px;
            font-style: normal;
            font-weight: 800;
            font-size: 50px;
            line-height: 120%;

            @media (max-width: 750px) {
                margin-top: 34px;
                font-size: 30px;
            }
        }

        .sub-title {
            margin-top: 10px;
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 120%;
            margin-bottom: 30px;

            @media (max-width: 750px) {
                margin-top: 1px;
                margin-bottom: 20px;
                font-size: 16px;
            }
        }

        .page-content {
            font-size: 18px;

            @media (max-width: 750px) {
                font-size: 14px;
            }

            .terms {
                margin-top: 10px;
            }
        }
    }
}