.credit-page {
    height: 100%;
    overflow: hidden;
    
    .banner {
        background: url('./background.jpg');
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        z-index: -1;
        height: calc(100vh - 70px);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }

    .content {
        position: fixed;
        margin-top: 7%;
        margin-left: 15%;

        @media (max-width: 780px) {
            margin-top: 20%;
            margin-left: 10%;
        }
        
        @media (max-height: 500px) {
            margin-top: -5%;
        }
    }

    .label-1 {
        font-size: 55px;
        font-weight: 700;
        color: white;
        width: 543px;

        @media (max-width: 780px) {
            font-size: 35px;
            width: 80%;
            text-align: left;
        }

        @media (max-width: 50px) {
            font-size: 25px;
            width: 80%;
            text-align: left;
        }
    }

    .form-1 {
        margin-top: 3%;
        display: flex;
        flex-direction: row;
        gap: 15px;

        @media (max-width: 780px) {
            margin-top: 15%;
            flex-direction: column;
        }

        .input-1 {
            border: none;
            border-radius: 20px;
            height: 57px;
            width: 200px;
            font-size: 14px;
            padding-left: 15px;
            text-transform: uppercase;
        }

        .button-1 {
            border: none;
            border-radius: 20px;
            height: 57px;
            width: 182px;
            font-size: 14px;
            padding-left: 15px;
            text-transform: uppercase;
            background-color: #7187A9;
            cursor: pointer;
            color: white;
        }
    }
}