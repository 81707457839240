* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'DM Sans';
}

html,
body {
    margin: 0;
}

body { 
    background-color: aliceblue;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: black;
}

button:enabled {
    cursor: pointer;
    &:hover {
        transition: opacity 0.1s;
        transform: scale(1.05)
    }
}

button:disabled {
    opacity: 0.5;
}