.home-page {
    height: 100%;
    overflow: hidden;
    
    .label-1 {
        position: absolute;
        font-size: 64px;
        top: 0;
        left: 5%;
        right: 5%;
        font-weight: 700;
        color: white;
        width: 90%;
        text-align: center;
        z-index: 0;
        margin-top: 5%;

        @media (max-width: 1000px) {
            font-size: 55px;
            margin-top: 10%;
        }

        @media (max-width: 780px) {
            font-size: 35px;
            margin-top: 20%;
        }

        @media (max-width: 780px) and (max-height: 780px) {
            margin-top: 15%;
        }

        @media (max-width: 500px) {
            font-size: 30px;
        }

        @media (max-width: 350px) {
            margin-top: 15%;
            font-size: 25px;
        }
    }

    .flex-group {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        gap: 0px;

        @media (max-width: 780px) {
            flex-direction: column;
        }

        .button {
            margin-top: calc(50vh - 100px);
            border: none;
            border-radius: 20px;
            width: 196px;
            font-size: 14px;
            padding-left: 15px;
            text-transform: uppercase;
            background-color: #7187A9;
            cursor: pointer;
            color: white;
            height: 57px;

            @media (min-width: 780px) and (max-height: 900px) {
                margin-top: calc(50vh - 50px);
            }
        }

        .banner-1 {
            width: 100vw;
            height: calc(100vh - 70px);
            background-size: cover;
            background-image: url(background1.jpg);

            @media (max-width: 780px) {
                height: calc(50vh - 35px);
                width: 100%;
            }

            .button-1 {
                margin-left: calc(100% - 250px);
                @extend .button;
                width: 196px;

                @media (max-width: 780px) {
                    margin-left: calc(50% - 98px);
                    margin-top: calc(50vh - 150px)
                }
            }
        }

        .banner-2 {
            width: 100vw;
            height: calc(100vh - 70px);
            background-size: cover;
            background-image: url(background2.jpg);

            @media (max-width: 780px) {
                height: calc(50vh - 35px);
                width: 100%;
            }

            .button-2 {
                margin-left: 30px;
                @extend .button;
                width: 240px;

                @media (max-width: 780px) {
                    margin-left: calc(50% - 120px);
                    margin-top: 30%;
                }
            }
        }
    }
}