/* navbar */
.navbar {
    background-color: aliceblue;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    min-height: 70px;
    color: #0B3C49;
    font-weight: 500;
}

/* logo  */
.logo {
    margin-left: 10%;
    font-size: 30px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;

    @media (max-width: 780px) {
        font-size: 20px;
    }
}

/* ul  */
.navMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    text-transform: uppercase;
    z-index: 1;
}

.navLink {
    border: none;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
}

@media screen and (max-width: 780px) {
    .hamburger {
        display: block;
        cursor: pointer;
    }

    .bar {
        background-color: #120f0b;
        width: 20px;
        height: 3px;
        display: block;
        margin: 5px;
        -webkit-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
    }

    /* adding active class to the bars  */
    .hamburger .active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger .active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger .active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }


    /* navMenu */

    .navMenu {
        position: absolute;
        flex-direction: column;
        gap: 0;
        top: 70px;
        left: -100%;
        text-align: start;
        width: 100%;
        transition: 0.4s ease-in-out;
        background-color: aliceblue;
        padding: 10px;
    }

    .navMenu.active {
        left: 0;
    }

    .navMenu li {
        margin: 16px 0;
    }
}