.page-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 18px;
    color: black;
    padding-bottom: 15px;
    font-weight: 700;
    position: relative;

    div {
        cursor: pointer;
    }

    a {
        text-decoration: none;
        color: black;
    }


    @media (max-width: 750px) {
        font-size: 14px;
        padding-bottom: 20px;
    }
}

.footer {
    left: calc(50% - 100px);
    position: absolute !important;
    top: calc(100% - 40px) !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    color: white !important;

    a {
        color: white !important;
    }
}


.footer-mobile {
    position: relative !important;
    color: black !important;
    margin: 30px 0px 20px 0px !important;
    padding: 0 !important;

    a {
        color: black !important;
    }
}


.footer-desktop {
    position: absolute !important;
    height: 100px;
    top: calc(100% - 100px) !important;
    color: black !important;
    margin: 0 !important;
    padding: 0 !important;

    a {
        color: black !important;
    }
}